import revive_payload_client_4sVQNw7RlN from "/opt/buildagent/work/6ac03ae5962f4eb7/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildagent/work/6ac03ae5962f4eb7/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildagent/work/6ac03ae5962f4eb7/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/buildagent/work/6ac03ae5962f4eb7/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/buildagent/work/6ac03ae5962f4eb7/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildagent/work/6ac03ae5962f4eb7/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/buildagent/work/6ac03ae5962f4eb7/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/buildagent/work/6ac03ae5962f4eb7/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildagent/work/6ac03ae5962f4eb7/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildagent/work/6ac03ae5962f4eb7/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_JaVKomdvyK from "/opt/buildagent/work/6ac03ae5962f4eb7/src/modules/auth/runtime/plugin.ts";
import primevue_plugin_egKpok8Auk from "/opt/buildagent/work/6ac03ae5962f4eb7/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/opt/buildagent/work/6ac03ae5962f4eb7/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_JaVKomdvyK,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp
]