type MiddlewareMeta = boolean | 'forbidden'

declare module '#app' {
  interface PageMeta {
    auth?: MiddlewareMeta
  }
}

export default defineNuxtRouteMiddleware((to) => {
  const { status } = useAuth()
  const pageAuthRule = to.meta.auth

  if (pageAuthRule === false) {
    return
  }

  if (pageAuthRule === 'forbidden') {
    if (status.value === 'authenticated') {
      return navigateTo('/', { external: true })
    } else {
      return
    }
  }

  if (
    status.value === 'unauthenticated' &&
    to.name !== 'login'
  ) {
    return navigateTo({
      name: useRuntimeConfig().public.auth
        .loginPageRouteName,
    })
  }
})
