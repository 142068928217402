export default {
  root: ({ props, context, parent }) => ({
    class: [
      'box-border',
      'min-w-[120px]',
      'h-[39px]',
      'rounded-[10px]',

      'border',
      props.error
        ? 'border-additional-red'
        : 'border-brand-blue',

      'font-normal',
      'text-brand-blue',
      'py-2',
      'px-2.5',
    ],
  }),
}
